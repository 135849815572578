// Base Colors
$white-color: #ffffff;
$nearly-white-color: #f8f8f8;
$near-white-color: #ccc;
$light-blue-color: rgb(221, 224, 250);
$blue-color: #7f8cff;
$blue-darker-color: #4457ff;
$light-gray-color: #a0a0a0;
$black-color: #000;
$gray-color: rgb(80, 80, 80);
$light-gray-color: rgb(232, 232, 232);
$red-color: #d41616;
$green-color: #00bd10;
$yellow-color: rgb(224, 224, 0);

// Element Colors
$shadow: #ccc;
$body-background: $black-color;
$content-container: $white-color;
$container-background: $white-color;
$text-color: $gray-color;

// Table
$header-color: $blue-color;
$header-text: $white-color;
$cell-border-color: $black-color;
$table-content-border-color: rgba(0, 0, 0, 0.3);
$header-receive-color: rgb(100, 173, 100);
$header-expense-color: rgb(173, 100, 100);

// Label input
$label-color: rgb(100, 100, 100);
$label-icon-color: rgb(70, 70, 70);
$input-background-color: $white-color;

// Button
$btn-background-color: $blue-color;
$btn-text-color: $white-color;
$btn-focus-color: $blue-darker-color;
$btn-low-priority-background-color: $light-gray-color;

// Modal
$overlay-color: rgba(0, 0, 0, 0.6);
$modal-background-color: $white-color;

$text-color: rgb(100, 100, 100);
$text-hightlight-color: $red-color;
$box-color: $blue-darker-color;
$button-color: $blue-darker-color;

// Error
$error-background-color: $blue-darker-color;
$error-text-color: #ffffff;
$error-border-color: $white-color;
$error-shadow-color: $white-color;

$border-color: #ccc;
$disabled-input: rgba(204, 204, 204, 0.4);

$list-background-color: #6776fd;
$list-text-color: $white-color;
$list-background-hover: $light-blue-color;

// Hint
$hint-text-color: $black-color;
$hint-text-header: rgb(119, 119, 119);
$hint-background-color: rgba(0, 0, 0, 0.1); //rgba(127, 140, 255, .9);
$hint-background-color-hover: rgba(
    0,
    0,
    0,
    0.3
); //rgba(127, 140, 255, .9);

$hightlight-text: $red-color;
$ignore-text-color: rgba(0, 0, 0, 0.3);

// note
$note-folder-background-color: #ebecf0;
$new-note-folder-background-color: rgba(228, 228, 228, 0.8);
$new-note-input-background-color: rgb(23, 43, 77);
$note-btn-background-color: rgb(0, 121, 191);
$new-note-input-border-color: rgb(23, 43, 77);
$note-focus-border-color: rgb(23, 43, 77);
$note-heading-color: #455570;
$note-text-color: #172b4d;

// action
$action-background-color: $blue-color;
$action-text-color: $white-color;

// toast
$toast_error: #ff623d;
$toast_success: #3dff94;

// nav settings
$nav-settings-background-color: #4759E4;
$nav-settings-hover-background-color: #3f52e4;
$nav-settings-hover-text-content-color: #ffffff;
$nav-settings-text-header-color: #B1BACC;
$nav-settings-text-content-color: #ffffff;
$nav-selected-settings-hover-background-color: #fff;
$nav-selected-settings-hover-text-color: #3f52e4;

// check box
$checkbox-border-color: $border-color;
$checkbox-background-color: $white-color;
$checkbox-checked-background-color: #2196F3;
